<template>
  <div class="selfMemory" v-loading="submitLoading" element-loading-text="数据提交中">
    <div class="container">
      <div class="progress">
        <div class="tip" :style="{ left: lineWidth * (checkLen / 24) + 'px' }">
          {{ checkLen }}/24
        </div>
        <div class="line"></div>
      </div>
      <ul>
        <li
          v-for="(item, index) in original"
          :key="index"
          @click="choose(item, index)"
          :class="item.answer == 1 ? 'check' : ''"
        >
          <span>{{ item.name }}</span>
          <img
            v-if="item.answer == 1 && checkLen >= 24"
            class="checkIcon"
            src="@/assets/image/self/check.png"
            alt=""
          />
        </li>
      </ul>
      <div
        :class="checkLen >= 24 ? 'submit canSave' : 'submit'"
        @click="submit"
      >
        <img src="@/assets/image/self/submit.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { saveSubjectiveAns } from '@/api/index';
const formalData = [
  {
    id: 1,
    mid: 'positive_01',
    type: 'positive',
    name: '友好',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 2,
    mid: 'positive_02',
    type: 'positive',
    name: '体贴',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 3,
    mid: 'positive_03',
    type: 'positive',
    name: '坚强',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 4,
    mid: 'positive_04',
    type: 'positive',
    name: '善良',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 5,
    mid: 'positive_05',
    type: 'positive',
    name: '认真',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 6,
    mid: 'positive_06',
    type: 'positive',
    name: '诚实',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 7,
    mid: 'positive_07',
    type: 'positive',
    name: '踏实',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 8,
    mid: 'positive_08',
    type: 'positive',
    name: '勤劳',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 9,
    mid: 'positive_09',
    type: 'positive',
    name: '自信',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 10,
    mid: 'positive_10',
    type: 'positive',
    name: '热情',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 11,
    mid: 'positive_11',
    type: 'positive',
    name: '温柔',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 12,
    mid: 'positive_12',
    type: 'positive',
    name: '勇敢',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 13,
    mid: 'negative_01',
    type: 'negative',
    name: '可恶',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 14,
    mid: 'negative_02',
    type: 'negative',
    name: '胆小',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 15,
    mid: 'negative_03',
    type: 'negative',
    name: '粗心',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 16,
    mid: 'negative_04',
    type: 'negative',
    name: '孤单',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 17,
    mid: 'negative_05',
    type: 'negative',
    name: '急躁',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 18,
    mid: 'negative_06',
    type: 'negative',
    name: '固执',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 19,
    mid: 'negative_07',
    type: 'negative',
    name: '多疑',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 20,
    mid: 'negative_08',
    type: 'negative',
    name: '狠心',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 21,
    mid: 'negative_09',
    type: 'negative',
    name: '虚荣',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 22,
    mid: 'negative_10',
    type: 'negative',
    name: '冒失',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 23,
    mid: 'negative_11',
    type: 'negative',
    name: '自私',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 24,
    mid: 'negative_12',
    type: 'negative',
    name: '懒惰',
    correct_answer: 1,
    answer: 0,
  },
  {
    id: 25,
    mid: 'posnew_01',
    type: 'positive',
    name: '孝顺',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 26,
    mid: 'posnew_02',
    type: 'positive',
    name: '节约',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 27,
    mid: 'posnew_03',
    type: 'positive',
    name: '正直',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 28,
    mid: 'posnew_04',
    type: 'positive',
    name: '聪明',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 29,
    mid: 'posnew_05',
    type: 'positive',
    name: '谦虚',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 30,
    mid: 'posnew_06',
    type: 'positive',
    name: '大方',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 31,
    mid: 'posnew_07',
    type: 'positive',
    name: '可爱',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 32,
    mid: 'posnew_08',
    type: 'positive',
    name: '快活',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 33,
    mid: 'posnew_09',
    type: 'positive',
    name: '活泼',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 34,
    mid: 'posnew_10',
    type: 'positive',
    name: '幸福',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 35,
    mid: 'posnew_11',
    type: 'positive',
    name: '优秀',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 36,
    mid: 'posnew_12',
    type: 'positive',
    name: '健康',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 37,
    mid: 'negnew_01',
    type: 'negative',
    name: '无知',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 38,
    mid: 'negnew_02',
    type: 'negative',
    name: '自满',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 39,
    mid: 'negnew_03',
    type: 'negative',
    name: '马虎',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 40,
    mid: 'negnew_04',
    type: 'negative',
    name: '愚笨',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 41,
    mid: 'negnew_05',
    type: 'negative',
    name: '无礼',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 42,
    mid: 'negnew_06',
    type: 'negative',
    name: '虚伪',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 43,
    mid: 'negnew_07',
    type: 'negative',
    name: '糊涂',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 44,
    mid: 'negnew_08',
    type: 'negative',
    name: '冷淡',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 45,
    mid: 'negnew_09',
    type: 'negative',
    name: '小气',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 46,
    mid: 'negnew_10',
    type: 'negative',
    name: '狡猾',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 47,
    mid: 'negnew_11',
    type: 'negative',
    name: '粗鲁',
    correct_answer: 0,
    answer: 0,
  },
  {
    id: 48,
    mid: 'negnew_12',
    type: 'negative',
    name: '自卑',
    correct_answer: 0,
    answer: 0,
  },
];
export default {
  data() {
    return {
      submitLoading:false,
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      original: [], //原始数据
      positiveCount: 0, //正性正确数
      negativeCount: 0, //负性正确数
      timer: null, //计时器
      timeout: false, //是否超时
      totalTime: 0, //用时
      lineWidth: 240,
    };
  },
  computed: {
    checkLen() {
      //选中的数量
      return this.original.filter((item) => item.answer).length;
    },
  },
  methods: {
    //选择
    choose(item, index) {
      if (item.answer == 1) {
        item.answer = 0;
      } else {
        if (this.checkLen >= 24) {
          this.$message({
            message: '已选择24个，请提交!',
            type: 'error',
          });
          return false;
        }
        item.answer = 1;
      }
      this.$set(this.original, index, item);
    },
    //提交
    submit() {
      if (this.checkLen >= 24 || this.timeout) {
        clearTimeout(this.timer);
        this.endTimeTotal = this.$util.getTimestamp();
        this.totalTime = this.endTimeTotal - this.startTimeTotal;
        this.original.forEach((item) => {
          if (item.answer == item.correct_answer) {
            item.correct_type = 1;
          } else {
            item.correct_type = 0;
          }
          if (item.answer == 1 && item.correct_answer == 1) {
            if (item.type == 'positive') {
              this.positiveCount++;
            }
            if (item.type == 'negative') {
              this.negativeCount++;
            }
          }
        });
        this.submitLoading = true;
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '5',
          quesNum: '5-3',
          totalTime: this.totalTime > 120000 ? 120000 : this.totalTime,
          data1Name: 'positiveCount',
          data1: this.positiveCount,
          data2Name: 'negativeCount',
          data2: this.negativeCount,
          dataJason: JSON.stringify(this.original),
          remark:
            'data1：1.正确选择的正性词语数量；data2：1.正确选择的负性词语数量；id：序号；mid：编码；type:类型；name:名称；correct_answer：正确答案 1应选择 0不应选择；answer：用户答案 1选择 0未选择；correct_type:是否正确，1正确，0错误；',
        };
        // console.log(query);
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code === 0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              // if (this.totalTime > 120000 - 1000) {
                this.$router.replace({
                  path: '/getCard',
                  query: {
                    id: 5,
                    current: '5-3',
                  },
                });
                this.submitLoading = false;
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 120000 - this.totalTime,
              //       path: '/getCard?id=5&current=5-3',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  created() {
    if (window.screen.width > 1900) {
      this.lineWidth = 312;
    } else {
      this.lineWidth = 240;
    }
    this.original = [];
    this.original = this.$util.shuffle(formalData);
  },
  mounted() {
    this.startTimeTotal = this.$util.getTimestamp();
    //四分钟后自动提交
    // this.timer = setTimeout(() => {
    //   this.timeout = true;
    //   this.submit();
    // }, 120000);
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .selfMemory {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 1050px;
      text-align: center;
      .progress {
        width: 280px;
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
        .tip {
          width: 40px;
          height: 22px;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          text-align: center;
          background-image: url('../../assets/image/other/progressTip.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
        }
        .line {
          width: 240px;
          height: 8px;
          display: inline-block;
          background: #5f9160;
          border-radius: 4px;
          margin-top: 25px;
        }
      }
      ul {
        width: 100%;
        text-align: center;
        overflow: hidden;
        li {
          width: 133px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          display: inline-block;
          font-size: 25px;
          color: #b5702d;
          margin: 10px 8px;
          font-family: 'SYHeiBold';
          background-image: url('../../assets/image/self/wordBg1.png');
          background-repeat: no-repeat;
          background-size: cover;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          position: relative;
        }
        .check {
          background-image: url('../../assets/image/self/wordBg2.png');
          color: #7a3398;
        }
        .checkIcon {
          width: 19px;
          height: 19px;
          display: inline-block;
          position: absolute;
          left: 57px;
          bottom: -8px;
        }
      }
      .submit {
        display: inline-block;
        margin-top: 30px;
        opacity: 0.3;
        cursor: not-allowed;
        img {
          widows: 210px;
          height: 70px;
        }
      }
      .canSave {
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        opacity: 1;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .selfMemory {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 1365px;
      text-align: center;
      .progress {
        width: 364px;
        margin: 0 auto;
        margin-bottom: 26px;
        position: relative;
        .tip {
          width: 52px;
          height: 29px;
          font-size: 16px;
          line-height: 23px;
          color: #ffffff;
          text-align: center;
          background-image: url('../../assets/image/other/progressTip.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
        }
        .line {
          width: 312px;
          height: 10px;
          display: inline-block;
          background: #5f9160;
          border-radius: 6px;
          margin-top: 32px;
        }
      }
      ul {
        width: 100%;
        text-align: center;
        overflow: hidden;
        li {
          width: 173px;
          height: 62px;
          line-height: 62px;
          text-align: center;
          display: inline-block;
          font-size: 32px;
          color: #b5702d;
          margin: 13px 10px;
          font-family: 'SYHeiBold';
          background-image: url('../../assets/image/self/wordBg1.png');
          background-repeat: no-repeat;
          background-size: cover;
          cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          position: relative;
        }
        .check {
          background-image: url('../../assets/image/self/wordBg2.png');
          color: #7a3398;
        }
        .checkIcon {
          width: 25px;
          height: 25px;
          display: inline-block;
          position: absolute;
          left: 74px;
          bottom: -10px;
        }
      }
      .submit {
        display: inline-block;
        margin-top: 39px;
        opacity: 0.3;
        cursor: not-allowed;
        img {
          widows: 273px;
          height: 91px;
        }
      }
      .canSave {
        cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
        opacity: 1;
      }
    }
  }
}
</style>
